@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

*::selection {
  color: white;
  background: black;
}


body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0%;
  margin-bottom: -4em;
  padding-bottom: 0em;
  margin-left: 2em;
  margin-right: 2em;
  background: #2c8b9f;
  color: #e6f9ff;
  opacity: 0.9;
  font-family: "Bebas Neue", cursive;
  height: 100%;
  min-height: 100%;
  -webkit-touch-callout: none;
}

.input {
  width: 3ch !important;
  border: 0;
  border-bottom: 2px solid #19222d;
  padding-top: 10%;
  padding-left: 0.5ch;
  padding-right: 0.5ch;
  display: flex;
  justify-content: center;
  margin: 0 auto !important;
  position: relative;
  font-family: "Bebas Neue", cursive;
  background: rgba(0, 0, 0, 0);
  color: #19222d;
  font-size: 3em;
  text-overflow: clip; /*clip, ellipsis*/
  overflow: hidden;
  white-space: nowrap;
}

.input::placeholder {
  color: #19222d;
  opacity: 0.5;
  text-align: center;
}

.input:focus {
  outline: none;
}

.output {
  text-align: center;
  font-size: larger;
  font-size: 1.5em;
  overflow: hidden;
  transition: 250ms ease-in all;
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 4em;
}
.info {
  font-size: 0.6em;
  visibility: visible;
  -ms-user-select:none;
  -moz-user-select:none;
  -webkit-user-select:none;
  -khtml-user-select: none;
  user-select:none;
  cursor: default;
}

@media screen and (min-width: 768px) {
  body {
    margin-top: 0%;
    overflow-x: hidden;
    display: block;
    /* text-align: center; */
  }

  .input {
    font-size: 2.2em;
    margin-top: 10%;
    display: block;
    width: max-content;
    text-align: left;
  }

  .output {
    font-size: 2em;
    transition: 250ms ease-in all;
  }

  .github-icon {
    transition: 150ms ease-in all;
  }

  .github-icon:hover {
    transform: scale(1.1);
    transition: 250ms ease-in all;
  }
  
  .footer {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;
  }
}